.pro-icon-wrapper {
  border-radius: 8px !important;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) !important;
  background: #222222 !important;
}


.pro-sidebar-inner {
  background-color: #323232 !important;
  z-index: 10000 !important;
}

.sr-menu-item-selected > .pro-inner-item > .pro-icon-wrapper {
  background: linear-gradient(135deg, #176766 0%, #0e5251 100%) !important;
  color: #e5e5e5 !important;
}

.pro-sidebar {
  position: fixed !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.pro-sidebar::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
