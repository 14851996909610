.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fa-spin {
  animation: spin infinite 2s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Popup */
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

/* Override bootstrap modal */
.modal {
  backdrop-filter: blur(4px);
}

.modal-content {
  border-radius: 12px !important;
    background-color: #323232 !important;
}

/* Override popup modal */
.popup-content {
  border-radius: 8px;
  /* width: 75%; */
  animation: anvil 0.5s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  -webkit-animation: anvil 0.5s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  background-color: #323232 !important;
  border-width: 0 !important;
}

.popup-arrow {
  display: none !important;
}

.popup-big-content {
  width: 90% !important;
}

.small-menu-item {
  color: #e5e5e5 !important;
  background-color: #2b2b2b !important;
  border-width: 0 !important;
}

.small-menu-item:hover {
  background-color: #e5e5e5 !important;
  color: #2b2b2b !important;
}

.disabled-menu-item {
  color: #a8a8a8 !important;
  font-weight: 900 !important;
  background-color: #2b2b2b !important;
  border-width: 0 !important;
}

.popup-small-right-menu-content,
.popup-small-right-menu-content:focus,
.popup-small-right-menu-content:active {
  width: auto !important;
  color: #e5e5e5 !important;
  background-color: #2b2b2b !important;
  left: unset !important;
  right: 20px !important;
  border-width: 0 !important;
}

.popup-small-content {
  width: 30% !important;
}

@media screen and (max-width: 800px) {
  .popup-small-content {
    width: 75% !important;
  }
}

/* input file */
input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1.5px solid #e5e5e5;
  color: #e5e5e5;
  background-color: #323232;
  /* box-shadow: 0 0 10px 0.5px; */
  border-radius: 6px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 16px;
}

.custom-file-upload:hover {
  box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.2) !important;
}

.box-shadow-hover:hover {
  box-shadow: 0px 0px 10px rgba(229, 229, 229, 0.2) !important;
}

.color-primary-hover:hover {
  color: #176766;
}

.color-primary-light-hover:hover {
  color: #8dc3c2;
}

/* BUTTON*/
.sr-btn {
  color: #e5e5e5;
  border-radius: 8px;
  border-width: 0;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  padding: 4px;
  background-color: #176766;
  width: 100%;
  font-size: 16px;
  padding: 11px 15px 11px 15px;
}

.sr-btn:hover:enabled {
  color: #e5e5e5 !important;
  background-color: #176766a8 !important;
  box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.2);
}

.sr-btn:disabled {
  background-color: rgba(239, 239, 239, 0.3) !important;
  color: rgba(16, 16, 16, 0.3) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
}

.sr-outline-btn {
  border: 1.5px solid #e5e5e5;
  color: #e5e5e5;
  background-color: #ffffff00;
  /* box-shadow: 0 0 10px 0.5px; */
  border-radius: 6px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 0 8px rgba(22, 22, 22, 0.4);
}

.sr-outline-btn:hover:enabled {
  box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.4);
}

.sr-outline-btn-danger:hover {
  box-shadow: 1px 1px 10px rgba(253, 0, 0, 0.4) !important;
}

.sr-outline-btn:disabled {
  background-color: rgba(239, 239, 239, 0.3) !important;
  color: rgba(16, 16, 16, 0.3) !important;
  border-color: rgba(118, 118, 118, 0.3) !important;
}

.sr-dark-btn {
  border-width: 0;
  color: #e5e5e5;

  background: #323232;
  /* Box shadow */

  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  padding: 8px 16px 8px 16px;
  font-size: 18px;
  line-height: 24px;
}

.sr-dark-btn:hover:enabled {
  color: #8dc3c2;
  background: #000000;
}

.sr-btn-hover:enabled:hover {
  box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.4);
}

.sr-btn-hover:disabled {
  opacity: 0.4 !important;
}

/* SweetAlert2 */

.swal2-styled.swal2-confirm {
  background-color: #176766 !important;
}

.swal2-loader {
  border-color: #1767666c !important;
}

/* TEXTAREA */
.sr-textarea {
  box-sizing: border-box;
  width: auto;
  border-width: 0;
  border-radius: 6px;
  background-color: #323232;
  color: #e5e5e5;
  padding: 7px 8px 7px 8px;
  resize: none;
  min-height: 100px;
}

.sr-textarea:active,
.sr-textarea:focus,
.sr-textarea:hover {
  box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.2) !important;
}

/* Input */
.sr-text-input {
  background-color: #323232 !important;
  color: #e5e5e5;
  display: flex !important;
  border-width: 0 !important;
  border-radius: 8px !important;
  padding: 7px 8px 7px 8px !important;
  outline: none !important;
  width: 100%;
}

.sr-text-input:disabled {
  color: #4e4e4e;
}

.sr-text-input:focus,
.sr-text-input:hover {
  box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.2) !important;
}

.shadow-hover:hover {
  box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.2) !important;
}

.sr-disabled {
  color: #4e4e4e;
}

/* Select */

.react-select__control,
.react-select__menu {
  background-color: #323232 !important;
  color: #e5e5e5 !important;
  display: flex !important;
  border-width: 0 !important;
  border-radius: 8px !important;
  min-width: 100% !important;
  padding: 0 !important;
  min-height: unset !important;
}

.react-select__control:hover,
.react-select__control--is-focused,
.react-select__control:active,
.react-select__control:focus,
.react-select__menu:hover,
.react-select__menu--is-focused,
.react-select__menu:active,
.react-select__menu:focus {
  box-shadow: 1px 1px 10px rgba(229, 229, 229, 0.2) !important;
}

.react-select__option--is-focused {
  background-color: unset !important;
  font-weight: 700 !important;
  width: 100% !important;
}

.react-select__menu-list {
  width: 100% !important;
}

.react-select__option {
  text-align: start !important;
  width: 100% !important;
}

.react-select__multi-value {
  background-color: #888888 !important;
}

.react-select__multi-value__label {
  color: #e5e5e5 !important;
  text-align: start !important;
}

.react-select__single-value {
  color: #e5e5e5 !important;
  text-align: start !important;
}

.react-select__single-value--is-disabled {
  color: #4e4e4e !important;
}

.react-select__option--is-selected {
  background-color: unset !important;
}

.react-select__input {
  color: #e5e5e5 !important;
}

/* Range slice */
.range-slider__wrap {
  color: #176766 !important;
}

input[type="range"].range-slider {
  color: #176766 !important;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4e4e4e;
}

.pointer {
  cursor: pointer !important;
}

/* -------- Data Table ----------- */

.rdt_TableHeadRow {
  background: #323232 !important;
  color: #e5e5e5;
  border: 1px solid #323232 !important;
  box-sizing: border-box !important;
  border-radius: 8px 8px 0px 0px !important;
}

.rdt_TableRow {
  border-color: #323232 !important;
  border-width: 0px 1px 0px 1px;
  box-sizing: border-box !important;
}

.rdt_TableCol,
.rdt_TableCell {
  border-color: #323232 !important;
  border-width: 0px 0px 0px 1px;
  box-sizing: border-box !important;
}
