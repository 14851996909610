@keyframes go-aside {
  0% {
    display: block;
    background-color: #1b1e21;
    width: 500px;
    height: 500px;
    right: 30%;
    top: 120px;
  }
  99% {
    border-radius: 4px;
    display: block;
    background-color: white;
    width: 0;
    height: 0;
    right: 10%;
    top: 200px;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

.popup-face {
  display: block;
  border-radius: 8px;
  width: 500px;
  height: 500px;
  background-color: #1b1e21;
  right: 30%;
  top: 120px;
  position: absolute;
  animation-name: go-aside;
  animation-duration: 1s;
  animation-delay: 1s;
  z-index: 10000;
}

.blur-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
  display: block;
}

.corners-only {
  position: relative;
}

.corners-only:before,
.corners-only:after,
.corners-only > :first-child:before,
.corners-only > :first-child:after {
  position: absolute;
  width: 80px;
  height: 80px;
  /* border-color: #8dc3c2; */
  /* or whatever colour */
  border-style: solid;
  /* or whatever style */
  content: " ";
}

.corners-only:before {
  top: 50px;
  left: 50px;
  border-width: 20px 0 0 20px;
  border-radius: 4px;
}

.corners-only:after {
  top: 50px;
  right: 50px;
  border-width: 20px 20px 0 0;
  border-radius: 4px;
}

.corners-only > :first-child:before {
  bottom: 50px;
  right: 50px;
  border-width: 0 20px 20px 0;
  border-radius: 4px;
}

.corners-only > :first-child:after {
  bottom: 50px;
  left: 50px;
  border-width: 0 0 20px 20px;
  border-radius: 4px;
}
