.body-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: left;
  background-image: url(../../assets/bg-dark.jpg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;
}

.login-box {
  width: 22rem;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}

.login-btn {
  align-content: center;
}
