.sr-image-person {
  height: 80px;
  width: 80px;
  border-radius: 8px;
}

.sr-image-person:hover {
  height: 250px;
  width: 250px;
  border-radius: 8px;
}
