#select-start {
  background-color: #494949 !important;
  color: #e5e5e5 !important;
  border-width: 0px !important;
}

#select-end {
  background-color: #494949 !important;
  color: #e5e5e5 !important;
  border-width: 0px !important;
}
